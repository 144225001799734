<template>
  <v-container :class="['wrapper', `wrapper--${color}`]" fill-height fluid>
    <span class="back-button" @click="onBackClick">
      BACK
    </span>
    <v-layout>
      <v-flex>
        <v-container fill-height>
          <v-layout align-center fill-height>
            <v-flex>
              <div class="content">
                <slot />
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'orange',
      validators: value => {
        return ['orange', 'blue'].includes(value);
      }
    }
  },
  methods: {
    onBackClick() {
      this.$emit('onback');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.wrapper {
  text-align: center;
  color: $white;
  padding: 0;

  &--blue {
    background-image: linear-gradient(-163deg, #4036a3 0%, $dark-blue 98%);
  }

  &--orange {
    background-image: linear-gradient(-161deg, #f7941e 3%, #f76b1c 100%);
  }
}

.back-button {
  position: fixed;
  z-index: 10;
  top: rem(15px);
  left: rem(20px);
  font-size: 20px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 5px 13px;
  border-radius: 25px;

  @include sm {
    top: rem(25px);
    left: rem(30px);
  }
}

.home-icon {
  /deep/ path {
    fill: $white;
  }
}

.content {
  margin: 0 auto;
  max-width: 100%;

  @include sm {
    padding: rem(50px 0);
  }
}
</style>
