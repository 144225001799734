var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-page-gradient-special-rate',{staticClass:"page",attrs:{"color":"blue"},on:{"onback":_vm.onCancel}},[_c('mobile-full-page-wrapper',{attrs:{"go-to-id":_vm.specialRatesFormId,"show-arrow":"","arrow-color":"white"}},[_c('v-layout',{staticClass:"no-grow",attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","sm10":"","md9":"","lg8":""}},[_c('typography',{attrs:{"type":"h3"}},[_vm._v("\n          Great news, let’s tailor a solution for you.\n        ")])],1)],1)],1),_c('mobile-full-page-wrapper',{attrs:{"id":_vm.specialRatesFormId}},[_c('v-layout',{attrs:{"justify-center":"","mb-5":""}},[_c('v-flex',{attrs:{"xs11":"","sm9":"","md7":"","lg6":""}},[_c('typography',[_vm._v("\n          As it's such a large volume, we'll get one of our Customer Managers\n          to be in touch with you within two working days, where they will\n          talk you through your needs and develop an offer tailored for your\n          business. If you do not require a call back and have a promo code\n          please use the \"I have a promo code\" button below.\n        ")])],1)],1),_c('form',{staticClass:"special-rates-form"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","sm8":"","md6":"","lg4":""}},[_c('text-input',{attrs:{"rules":[
              function () { return _vm.$v.fullName.required || 'This field is required'; },
              function () { return _vm.$v.fullName.maxLength || 'The name is too long'; }
            ],"color":"white","label":"Full name","maxlength":250},on:{"blur":function($event){return _vm.$v.fullName.$touch()}},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"fullName"}})],1)],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","sm8":"","md6":"","lg4":""}},[_c('text-input',{attrs:{"rules":[
              function () { return _vm.$v.email.required || 'This field is required'; },
              function () { return _vm.$v.email.email || 'Please provide a valid email address'; }
            ],"color":"white","type":"email","label":"Email"},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})],1)],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","sm8":"","md6":"","lg4":""}},[_c('text-input',{attrs:{"rules":[
              function () { return _vm.$v.contactNumber.required || 'This field is required'; },
              function () { return _vm.$v.contactNumber.phone || 'Contact number is not valid'; }
            ],"color":"white","type":"tel","label":"Contact number"},on:{"blur":function($event){return _vm.$v.contactNumber.$touch()}},model:{value:(_vm.contactNumber),callback:function ($$v) {_vm.contactNumber=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"contactNumber"}})],1)],1),_c('v-layout',{attrs:{"justify-center":"","mt-5":""}},[_c('v-flex',{attrs:{"xs10":"","sm6":"","md7":""}},[_c('round-button',{attrs:{"disabled":_vm.$v.$invalid,"loading":_vm.submitRequestCallbackProgress.inProgress,"color":"white","type":"button"},on:{"click":_vm.onSubmit}},[_vm._v("Request a callback")]),_c('round-button',{attrs:{"color":"white","outline":"","type":"button"},on:{"click":_vm.onCancel}},[_vm._v("I have a promo code")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }