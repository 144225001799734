<template>
  <full-page-gradient-special-rate color="blue" class="page" @onback="onCancel">
    <!-- Special rates header -->
    <mobile-full-page-wrapper
      :go-to-id="specialRatesFormId"
      show-arrow
      arrow-color="white"
    >
      <v-layout class="no-grow" justify-center>
        <v-flex xs11 sm10 md9 lg8>
          <typography type="h3">
            Great news, let’s tailor a solution for you.
          </typography>
        </v-flex>
      </v-layout>
    </mobile-full-page-wrapper>

    <!-- Special rates form -->
    <mobile-full-page-wrapper :id="specialRatesFormId">
      <v-layout justify-center mb-5>
        <v-flex xs11 sm9 md7 lg6>
          <typography>
            As it's such a large volume, we'll get one of our Customer Managers
            to be in touch with you within two working days, where they will
            talk you through your needs and develop an offer tailored for your
            business. If you do not require a call back and have a promo code
            please use the "I have a promo code" button below.
          </typography>
        </v-flex>
      </v-layout>

      <form class="special-rates-form">
        <!-- Full name -->
        <v-layout justify-center>
          <v-flex xs11 sm8 md6 lg4>
            <text-input
              v-model.trim="fullName"
              :rules="[
                () => $v.fullName.required || 'This field is required',
                () => $v.fullName.maxLength || 'The name is too long'
              ]"
              color="white"
              label="Full name"
              @blur="$v.fullName.$touch()"
              :maxlength="250"
            />
          </v-flex>
        </v-layout>
        <!-- Email field -->
        <v-layout justify-center>
          <v-flex xs11 sm8 md6 lg4>
            <text-input
              v-model.trim="email"
              :rules="[
                () => $v.email.required || 'This field is required',
                () => $v.email.email || 'Please provide a valid email address'
              ]"
              color="white"
              type="email"
              label="Email"
              @blur="$v.email.$touch()"
            />
          </v-flex>
        </v-layout>
        <!-- Contact number -->
        <v-layout justify-center>
          <v-flex xs11 sm8 md6 lg4>
            <text-input
              v-model.trim="contactNumber"
              :rules="[
                () => $v.contactNumber.required || 'This field is required',
                () => $v.contactNumber.phone || 'Contact number is not valid'
              ]"
              color="white"
              type="tel"
              label="Contact number"
              @blur="$v.contactNumber.$touch()"
            />
          </v-flex>
        </v-layout>

        <!-- Buttons -->
        <v-layout justify-center mt-5>
          <v-flex xs10 sm6 md7>
            <round-button
              :disabled="$v.$invalid"
              :loading="submitRequestCallbackProgress.inProgress"
              color="white"
              type="button"
              @click="onSubmit"
              >Request a callback</round-button
            >
            <round-button color="white" outline type="button" @click="onCancel"
              >I have a promo code</round-button
            >
          </v-flex>
        </v-layout>
      </form>
    </mobile-full-page-wrapper>
  </full-page-gradient-special-rate>
</template>

<script>
import FullPageGradientSpecialRate from '@/components/templates/FullPageGradientSpecialRate.vue';
import Typography from '@/components/atoms/Typography.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import { email, phone } from '@/validators';
import { FORM, CALLBACK_REQUESTED } from '@/constants/routes';
import { SPECIAL_RATES_FORM, SECTION_YOUR_OFFER } from '@/constants/html-ids';
import { mapState } from 'vuex';

export default {
  components: {
    Typography,
    TextInput,
    RoundButton,
    FullPageGradientSpecialRate,
    MobileFullPageWrapper
  },
  computed: {
    fullName: {
      get() {
        return this.$store.state.specialRates.fullName;
      },
      set(value) {
        this.$store.dispatch('specialRates/changeFullName', value);
      }
    },
    email: {
      get() {
        return this.$store.state.specialRates.email;
      },
      set(value) {
        this.$store.dispatch('specialRates/changeEmail', value);
      }
    },
    contactNumber: {
      get() {
        return this.$store.state.specialRates.contactNumber;
      },
      set(value) {
        this.$store.dispatch('specialRates/changeContactNumber', value);
      }
    },
    ...mapState({
      submitRequestCallbackProgress: state =>
        state.progress.submitRequestCallback
    }),
    specialRatesFormId: () => SPECIAL_RATES_FORM
  },
  mounted() {
    this.$store.dispatch('specialRates/prepopulateFullName');
    this.$store.dispatch('specialRates/prepopulateEmail');
  },
  methods: {
    async onSubmit() {
      await this.$store.dispatch('saver/submitRequestCallback');

      if (this.submitRequestCallbackProgress.success) {
        this.$router.push(CALLBACK_REQUESTED);
      }
    },
    onCancel() {
      this.$router.push({ path: FORM, hash: `#${SECTION_YOUR_OFFER}` });
    }
  },
  validations: {
    fullName: {
      required,
      maxLength: maxLength(250)
    },
    email: {
      required,
      email
    },
    contactNumber: {
      required,
      phone
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.page {
  color: $white;
}

.text-input {
}

.success-message {
  color: $white;
}

.special-rates-form {
  color: $dark-blue;
}
</style>
